import { useCallback, useEffect, useState } from 'react';

const values = {
  startX: 0,
  startY: 0,
  startTime: 0,
};

const calculateAngle = (deltaX: number, deltaY: number, distance: number) => {
  const unitVector = [deltaX / distance, deltaY / distance];
  const referenceVector = [0, 1];
  const vectorDot =
    unitVector[0] * referenceVector[0] + unitVector[1] * referenceVector[1];

  const angle = Math.acos(vectorDot);
  const angleDeg = (angle * 180) / Math.PI;
  // Return corrected value from 0 - 360 deg
  if (unitVector[0] < 0) {
    return 180 + angleDeg;
  } else {
    return 180 - angleDeg;
  }
};

const getDirection = (angleDeg: number): Direction => {
  if (angleDeg > 45 && angleDeg <= 135) {
    return 'right';
  } else if (angleDeg > 135 && angleDeg <= 225) {
    return 'down';
  } else if (angleDeg > 225 && angleDeg <= 315) {
    return 'left';
  } else {
    return 'up';
  }
};

const getXY = (event: TouchEvent) => [
  event.changedTouches[0].pageX,
  event.changedTouches[0].pageY,
];

const validateSwipe = ({ config, direction, speed }: ValidateSwipeOptions) => {
  if (speed < config.speedThreshold) {
    return false;
  }

  if (config.direction === 'any') {
    return true;
  }

  return config.direction === direction;
};

type Direction = 'up' | 'down' | 'left' | 'right';

type ValidateSwipeOptions = OnSwipeOptions & {
  config: SwipeDetectConfig;
};

type OnSwipeOptions = {
  angle: number;
  speed: number;
  direction: Direction;
};

type SwipeDetectConfig = {
  speedThreshold: number;
  direction: Direction | 'any';
};

type SwipeDetectOptions<T> = {
  element: T extends HTMLElement ? T : null;
  onSwipe?: (opts: OnSwipeOptions) => void;
  configuration?: Partial<SwipeDetectConfig>;
};

export const useSwipeDetect = <T = HTMLElement>({
  element,
  onSwipe,
  configuration,
}: SwipeDetectOptions<T>) => {
  const [hasEventListeners, setHasEventListeners] = useState(false);

  const setStart = useCallback((event: TouchEvent) => {
    const [x, y] = getXY(event);
    values.startX = x;
    values.startY = y;
    values.startTime = Date.now();
  }, []);

  const setEnd = useCallback(
    (event: TouchEvent) => {
      const [x, y] = getXY(event);
      const { startX, startY, startTime } = values;
      const [deltaX, deltaY] = [x - startX, y - startY];
      const deltaTime = Date.now() - startTime;
      const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
      const config: SwipeDetectConfig = {
        direction: 'any',
        speedThreshold: 250,
        ...configuration,
      };

      if (distance) {
        const angle = calculateAngle(deltaX, deltaY, distance);
        const speed = distance / (deltaTime / 1000);
        const direction = getDirection(angle);

        if (validateSwipe({ config, angle, speed, direction })) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onSwipe && onSwipe({ angle, speed, direction });
        }
      }
    },
    [configuration, onSwipe],
  );

  useEffect(() => {
    if (element && !hasEventListeners) {
      element.addEventListener('touchstart', setStart, { passive: true });
      element.addEventListener('touchend', setEnd);
      setHasEventListeners(true);
    }

    return () => {
      if (element) {
        document.body.removeEventListener('touchstart', setStart);
        document.body.removeEventListener('touchend', setEnd);
      }
    };
  }, [element, hasEventListeners, setEnd, setStart]);
};
