import Drawer from 'components/Drawer';
import Icons from 'components/icons';
import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Flag from 'react-world-flags';
import enabledLanguages from '../../../enabledLanguages';

const CircleFlag = ({ code, small }: { code: string; small?: boolean }) => (
  <div
    className={`relative rounded-full overflow-hidden ${
      small ? 'w-6 h-6' : 'w-8 h-8'
    }`}
  >
    <div
      className={`absolute top-0 left-1/2 -translate-x-1/2 w-max ${
        small ? 'h-6' : 'h-8'
      }`}
    >
      <Flag
        code={code}
        height={small ? '24' : '32'}
        className={small ? 'h-6' : 'h-8'}
        alt={`flag for language ${code}`}
      />
    </div>
  </div>
);

const FlagLink = ({
  locale,
  onClick,
  flagCode,
  text,
}: {
  text: string;
  flagCode: string;
  locale: string;
  onClick: () => void;
}) => {

  const router = useRouter();
  
  return (
  <button aria-label={locale} className="text-link" onClick={() => {
    router.push(router.asPath, router.asPath, { locale })
    onClick()
  }}>
    <div className="flex items-center gap-4">
      <CircleFlag code={flagCode} />
      <Text bold size="large" elementType="div" color="blue">
        {text}
      </Text>
    </div>
  </button>
);}

const FlagButton = ({
  locale = 'en',
  onClick,
}: {
  locale?: string;
  onClick: () => void;
}) => {
  let code = locale;
  if (code === 'sv') {
    code = 'se';
  }

  if (code === 'en') {
    code = 'gb';
  }

  if (code === 'da') {
    code = 'dk';
  }

  return (
    <button
      onClick={onClick}
      aria-label="Language selector"
      className="absolute top-8 right-4 sm:top-10 sm:right-10 flex items-center gap-4 p-2"
    >
      <CircleFlag small code={code} />
      <Icons.Chevron className="rotate-90 text-blue" />
    </button>
  );
};

const LanguageSelector = () => {
  const translation = useTranslation();
  const { locale } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <FlagButton onClick={() => setIsOpen(!isOpen)} locale={locale} />

      <Drawer
        isOpen={isOpen}
        position="center"
        transition="fade"
        backdropClassName="bg-black/30"
        onClose={() => setIsOpen(false)}
      >
        <div className="bg-white w-[80vw] max-w-[800px] rounded-xl p-8">
          <Button
            style="onlyText"
            color="blue"
            onClick={() => setIsOpen(false)}
            className="absolute top-4 right-4 !h-12 aspect-square"
            ariaLabel="close"
          >
            <Icons.Close />
          </Button>
          <Text center bold size="xlarge" elementType="div" className="my-6">
            {translation('selectLanguage')}
          </Text>
          <div className="grid grid-cols-3 gap-y-10 gap-x-6">
            {enabledLanguages.full.map(({ name, flag, lang }, index) => (
              <FlagLink
                key={`flag-link-${index}-${lang}`}
                text={name}
                flagCode={flag}
                locale={lang}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default LanguageSelector;
