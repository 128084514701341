import imageUrlBuilder from '@sanity/image-url';
import { ClientConfig, createClient } from 'next-sanity';
import type { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { DataFactory } from 'factories/dataFactory';

export const config: ClientConfig = {
  projectId: 'mhap5tav',
  dataset: 'production',
  apiVersion: '2021-10-21',
  useCdn: false,
};

export const client = createClient(config);

// type ApiArgs = Parameters<typeof fetch>;

// export const api = <T>(input: ApiArgs[0], init?: ApiArgs[1]): Promise<T> => {
//   const headers = {
//     Authorization: `Bearer ${process.env.NEXT_PUBLIC_NORDMOUNT_PLANNER_API_TOKEN}`,
//     'Content-Type': 'application/json',
//   };

//   const url = `/api${input}`;

//   return fetch(url, {
//     ...init,
//     headers: {
//       ...init?.headers,
//       ...headers,
//     },
//   }).then((res) => res.json());
// };

type ClientArgs = Parameters<typeof client.fetch>;

export const sanityFetch = <T extends any[]>(
  query: ClientArgs[0],
  params?: ClientArgs[1],
): Promise<T> =>
  client
    .fetch(query, params)
    .then((res) => DataFactory.recursiveAddKeysToArray(res));

const builder = imageUrlBuilder(client);

export const fileUrl = (
  image: SanityImageObject & {
    localMedia?: string;
  },
) => {
  // Example ref: file-207fd9951e759130053d37cf0a558ffe84ddd1c9-mp3
  // We don't need the first part, unless we're using the same function for files and images
  const [, id, extension] = image.asset._ref.split('-');

  return `https://cdn.sanity.io/files/${config.projectId}/${config.dataset}/${id}.${extension}`;
};

export const imageUrl = (
  image: SanityImageObject & {
    localMedia?: string;
  },
) => builder.image(image).quality(100);

export const imageDimensions = (
  image: SanityImageObject & {
    localMedia?: string;
  },
) => {
  const { options } = builder.image(image);
  const imgSource = options.source as any;
  if (imgSource?.asset?._ref) {
    const [, , dimensions] = imgSource.asset._ref.split('-');
    const [width, height] = dimensions
      .split('x')
      .map((num: string) => parseInt(num, 10));
    const aspectRatio = width / height;

    return { width, height, aspectRatio };
  }

  return { width: 0, height: 0, aspectRatio: 1 };
};
